import {AxonCoverage, AxonFields, AxonLabel, Coverage, CoverageData, CoverageTypeName, PaymentText} from "./types"
import {locale} from "lib/i18n/locale/nl-NL"
import dateFormatter from "lib/i18n/format/date"
import moneyFormatter from "lib/i18n/format/money"
import numberFormatter from "lib/i18n/format/number"
import {epochDate, cleanFrequency} from "leven-shared-components/typings/convert"

export const coveragesFilter = (coverages: Array<Coverage> | undefined, coverageTypeName: CoverageTypeName) =>
			coverages?.filter(coverage => coverage.coverageTypeName === coverageTypeName) || []

const coverValue = (coverageFieldList: AxonCoverage["coverageFieldList"], labelText: string) => {
	const value = coverageFieldList.find(coverageField => coverageField.labelText === labelText)?.value

	if (value) {
		if (value.typeOfValue === "DATE") {
			const date = epochDate(value.value)
			return date ? dateFormatter(locale)("short")(date) : undefined
		} else if (value.typeOfValue === "PERCENTAGE") {
			return numberFormatter(locale)(Number(value.value))
		} else if (value.typeOfValue === "AMOUNT") {
			const numberValue = Number(value.value)
			const amount = labelText === "Bedrag verandering per termijn" && numberValue < 0 ?
			Math.abs(numberValue) : numberValue
			return moneyFormatter(locale)(amount)
		} else {
			return value.value
		}
	}
	return undefined
}

const axonFields: AxonFields = {
	insuredAmount: {
		axonLabel: AxonLabel.INSUREDAMOUNT,
		targetField: "insuredAmount"
	},
	interestRate: {
		axonLabel: AxonLabel.INTERESTRATE,
		targetField: "interestRate"
	},
	frequency: {
		axonLabel: AxonLabel.FREQUENCY,
		targetField: "frequency"
	},
	installmentAmount: {
		axonLabel: AxonLabel.INSTALLMENTAMOUNT,
		targetField: "installmentAmount"
	},
	endDateInterest: {
		axonLabel: AxonLabel.ENDDATEINTEREST,
		targetField: "endDateInterest"
	},
	startDate: {
		axonLabel: AxonLabel.STARTDATE,
		targetField: "startDate"
	},
	endDate: {
		axonLabel: AxonLabel.ENDDATE,
		targetField: "endDate"
	},
	changeAmountPerTerm: {
		axonLabel: AxonLabel.CHANGEAMOUNTPERTERM,
		targetField: "changeAmountPerTerm"
	},
	insuredPercentage: {
		axonLabel: AxonLabel.INSUREDPERCENTAGE,
		targetField: "insuredPercentage"
	},
	receiver: {
		axonLabel: AxonLabel.RECEIVER,
		targetField: "receiver"
	},
	insuredAmountEnddate: {
		axonLabel: AxonLabel.INSUREDAMOUNTENDDATE,
		targetField: "insuredAmountEnddate"
	},
	survivor: {
		axonLabel: AxonLabel.SURVIVOR,
		targetField: "survivor"
	},
	survivorInterest: {
		axonLabel: AxonLabel.SURVIVORINTEREST,
		targetField: "survivorInterest"
	}
}

const coverageConfig = [
	{
		coverageTypeName: CoverageTypeName.DEATH_COVERAGE,
		name: ["Gelijkblijvend"],
		fieldsToProcess: [
			axonFields.insuredAmount
		],
		text: (data: CoverageData) => `${data.insuredAmount}`
	},
	{
		coverageTypeName: CoverageTypeName.DEATH_COVERAGE,
		name: ["Annuïtair"],
		fieldsToProcess: [
			axonFields.insuredAmount,
			axonFields.interestRate
		],
		text: (data: CoverageData) => `${data.insuredAmount}. Het bedrag wijzigt maandelijks. Het annuiteiten percentage is ${data.interestRate}% op jaarbasis.`
	},
	{
		coverageTypeName: CoverageTypeName.DEATH_COVERAGE,
		name: ["Lineair", "Lineair langstlevende overlijdensdekking"],
		fieldsToProcess: [
			axonFields.insuredAmount,
			axonFields.changeAmountPerTerm,
			axonFields.interestRate,
			axonFields.insuredAmountEnddate
		],
		text: (data: CoverageData) => `${data.insuredAmount}. Het bedrag wijzigt jaarlijks met een evenredig deel van ${data.changeAmountPerTerm} van {POLICY_STARTDATE} naar ${data.insuredAmountEnddate || "€ 0,-"} op {POLICY_ENDDATE}.`
	},
	{
		coverageTypeName: CoverageTypeName.DEATH_COVERAGE,
		name: ["Percentage"],
		fieldsToProcess: [
			axonFields.startDate,
			axonFields.insuredAmount
		],
		text: (data: CoverageData) => `Wordt het verzekerd bedrag uitbetaald. Het verzekerde bedrag is op ${data.startDate} ${data.insuredAmount}. Het verzekerde bedrag stijgt maandelijks met een percentage van ??% op jaarbasis.`
	},
	{
		coverageTypeName: CoverageTypeName.DEATH_COVERAGE,
		name: ["Percentage fondswaarde"],
		fieldsToProcess: [
			axonFields.insuredPercentage
		],
		text: (data: CoverageData) => `Het verzekerde bedrag is ${data.insuredPercentage}% van de waarde van de verzekering op het moment van overlijden.`
	},
	{
		coverageTypeName: CoverageTypeName.DEATH_COVERAGE,
		name: ["Premierestitutie"],
		text: "Wordt de koopsom en/ of de som van de tot dat moment betaalde premies uitbetaald."
	},
	{
		coverageTypeName: CoverageTypeName.DEATH_COVERAGE,
		name: ["Overlevingsrente 1pct", "Overlevingsrente 3pct", "Overlevingsrente 4pct", "Overlevingsrente voortzetting 0pct"],
		fieldsToProcess: [
			axonFields.frequency,
			axonFields.survivor,
			axonFields.installmentAmount
		],
		text: (data: CoverageData) => `Een periodieke uitkering ${cleanFrequency(data.frequency)} van ${data.installmentAmount} {POLICY_ENDDATE} zolang ${data.survivor} leeft.`
	},
	{
		coverageTypeName: CoverageTypeName.DEATH_COVERAGE,
		name: ["Erfrente 1pct", "Erfrente 3pct", "Erfrente 4pct"],
		fieldsToProcess: [
			axonFields.frequency,
			axonFields.installmentAmount
		],
		text: (data: CoverageData) => `Een periodieke uitkering ${cleanFrequency(data.frequency)} van ${data.installmentAmount} {POLICY_ENDDATE}.`
	},
	{
		coverageTypeName: CoverageTypeName.DEATH_COVERAGE,
		name: ["Vaste termijn"],
		fieldsToProcess: [
			axonFields.insuredAmount,
			axonFields.frequency
		],
		text: (data: CoverageData) => `Bij overlijden op of na {POLICY_STARTDATE}, maar vóór {POLICY_ENDDATE} wordt de waarde van ${data.insuredAmount} op {POLICY_ENDDATE} uitgekeerd. De waarde wordt berekend met een rente van <Renteperc>% op jaarbasis.`
	},
	{
		coverageTypeName: CoverageTypeName.LIFE_COVERAGE,
		name: ["Lijfrente postnumerando", "Periodieke opname (postnumerando)"],
		fieldsToProcess: [
			axonFields.startDate,
			axonFields.endDate,
			axonFields.receiver,
			axonFields.installmentAmount,
			axonFields.frequency,
			axonFields.survivorInterest
		],
		text: (data: CoverageData) => `Dan stopt de verzekering en wordt er een slotuitkering uitbetaald. Het bedrag van de slotuitkering wordt naar evenredigheid van de (lijfrente) uitkering vastgesteld over de periode vanaf de dag waarop de laatste uitkering betaalbaar is gesteld tot en met de datum waarop ${data.receiver} is overleden.`,
		paymentText: (data: CoverageData) => {

			const text: PaymentText = {
				Ingangsdatum: `${data.startDate}`,
				Einddatum: `${data.endDate}`,
				"Periodieke uitkering": `${data.installmentAmount} (bruto) ${cleanFrequency(data.frequency)}`,
				"Overgang bij overlijden": `${data.survivorInterest || "Nee" }`,
				Ontvanger: `${data.receiver}`
			}

			return text
		}
	}
]

export const convertCoverages = (coverages: Array<AxonCoverage> | null) => {

	const convertedCoverages: Array<Coverage> = []

	if (coverages) {
		for (const coverage of coverages) {

			const conversionRules = coverageConfig.find(rules => rules.name.includes(coverage.name))

			if (conversionRules) {
				let coverageData: CoverageData = {}
				let fieldsProcessed = 0
				let text = ""
				let paymentText: PaymentText | undefined

				if (conversionRules.fieldsToProcess) {
					for (const fieldConfig of conversionRules.fieldsToProcess) {

						const keyValuePair = {
							[fieldConfig.targetField]: coverValue(coverage.coverageFieldList, fieldConfig.axonLabel)
						}

						coverageData = {...coverageData, ...keyValuePair}

						fieldsProcessed++

						if (fieldsProcessed === conversionRules.fieldsToProcess.length) {
							text = conversionRules.text(coverageData)
							if (conversionRules.paymentText) {
								paymentText = conversionRules.paymentText(coverageData)
							}
						}
					}
				}

				convertedCoverages.push({
					coverageTypeName: coverage.coverageTypeName,
					name: coverage.name,
					text: text || conversionRules.text,
					paymentText
				})

			} else {
				if (coverage.coverageTypeName === CoverageTypeName.EXTRA_COVERAGE) {
					convertedCoverages.push({
						coverageTypeName: coverage.coverageTypeName,
						name: coverage.name,
						text: coverage.name
					})
				}
			}

		}
	}
	return convertedCoverages
}
